import Template from '../components/Template'
import * as style from './style.module.scss'
import React from 'react'
import { Helmet } from 'react-helmet'

const PageTerms = () => (
  <Template>
    <Helmet>
      <title>Privacy policy</title>
    </Helmet>
    <div className={style.pageWrapper}>
      <div className={style.titleContainer}>
        <h1>Privacy policy</h1>
        <p>
          <strong>Last updated:</strong> December 2, 2023
        </p>
      </div>
      <p>
        We collect and manage user data according to the following privacy
        policy.
      </p>
      <h3>Data collected</h3>
      <p>
        We collect data from every visitor to monitor traffic and fix bugs. For
        example, we collect information like web requests, the data sent in
        response to such requests, IP address, geolocation information such as
        city & state, browser type, browser language, and request timestamp.
      </p>
      <p>
        In order to take advantage of certain features of the website, you may
        choose to provide us with additional personal information, such as your
        email, picture, address, social media handle(s), business name, state
        licensing information, pet information, or your personal website, but
        your decision to utilize these features and provide such data will{' '}
        always be voluntary.
      </p>
      <h3>Use of the data </h3>
      <p>
        We do not share personal information you have provided to us, unless:
      </p>
      <ul>
        <li>Doing so is appropriate to carry out your own request;</li>
        <li>
          We believe it's needed to enforce our terms of service, or that is
          legally required;
        </li>
        <li>
          We believe it's needed to detect, prevent or address fraud or
          technical issues;
        </li>
      </ul>
      <p>
        Some website data is periodically transmitted to external services to
        help improve the website. The service we currently use is called{' '}
        <a href='https://posthog.com' target='_blank'>
          {' '}
          "PostHog"
        </a>{' '}
        which has their own privacy policy.
      </p>
      <h3>Cookie policy</h3>
      <p>
        We only use essential first-party cookies for basic website
        functionality. No third-party cookies are used. You can manage cookie
        settings through your browser. Disabling cookies may affect the way this
        website functions.
      </p>
      <h3>Changes to the privacy policy</h3>
      <p>
        We may amend this privacy policy from time to time. Use of information
        we collect is subject to the privacy policy in effect at the time such
        information is used.
      </p>
    </div>
  </Template>
)

export default PageTerms
